<template>
  <div id="QuestionForm">
    <div class="title-exam">
      <svg-icon iconClass="project" size="30" style="margin-right:6px" />{{form.title}}
    </div>
    <van-form ref="form" class="form" style="margin-bottom:65px">
      <van-cell-group :border="true">
        <van-cell size="large" v-for="(item,index) in form.examinationBanks" :key="index" :border="true">
          <template #title>
            <span>{{(index+1)+". "+item.issueTitle}}</span>
            <van-tag type="success" round style="margin-left:10px;padding: 3px 10px;">{{  codeConvertLabel(item.issueType, dict.question_type)}}</van-tag>
          </template>
          <template #label>
            <div class="exame-radio" v-if="item.issueType === 1">
              <van-field :required="true" :rules="[{ required: true  } ]">
                <template #input>
                  <van-radio-group v-model="item.issueCheckId">
                    <van-radio class="radio-item" :name="itm2.id" @click.native="clickRadio(itm2,item)" v-for="(itm2,index1) in item.examinationContents" :key="index1">{{(EnNumber[index1])+'. '+itm2.option}}</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </div>
            <div class="exame-check" v-if="item.issueType === 2">
              <van-field :required="true" :rules="[{ required: true  } ]">
                <template #input>
                  <van-checkbox-group v-model="item.checkBoxIds" :required="true" :rules="[{ required: true  } ]">
                    <van-checkbox @change="checkBoxChange(item3,item)" class="checkbox-item" shape="square" :name="itm3.id" v-for="(itm3,index3) in item.examinationContents" :key="index3">{{(EnNumber[index3])+'. '+itm3.option}}</van-checkbox>
                  </van-checkbox-group>
                </template>
              </van-field>
            </div>
            <div class="exame-input" v-if="item.issueType === 3">
              <van-field v-model="item.issueAnswer" :border="true" rows="3" autosize type="textarea" placeholder="请填写" :required="true" :rules="[{ required: true  } ]" />
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </van-form>
    <div class="submit-button shadow-style">
      <div>
        <van-button round block type="primary" :loading="loading" @click="doSubmit()">提 交</van-button>
      </div>
    </div>
    </van-form>

  </div>
</template>

<script>
import examination from '@/api/modules/question/examination'
import { report, findById } from '@/api/modules/question/invTaskStu'
import { codeConvertLabel } from "@/utils";
import { addBatch } from '@/api/modules/question/invTaskStuScore'

export default {
  name: "QuestionForm",
  dicts: ['question_type'],
  data() {
    return {
      form: {},
      EnNumber: {
        0: 'A',
        1: 'B',
        2: 'C',
        3: 'D',
        4: 'E',
        5: 'F',
        6: 'G',
        7: 'H',
        8: 'I',
        9: 'J',
        10: 'K',
        11: 'L',
        12: 'M',
        13: 'N',
        14: 'O',
        15: 'P',
        16: 'Q',
      },
      loading: false,
    }
  },
  created() {
    examination.findById(this.$route.query.id).then((res) => {
      console.log(res)
      this.form = res
    })
    findById(this.$route.query.zbid).then((res) => {
      console.log(res)
      if (res.status === 1) {
        // this.$alert('检测到该问卷您已经提交，无需重复提交!' 
      }
    })
  },
  methods: {
    codeConvertLabel,
    clickRadio(item2, item) {
      this.$set(item, 'issueCheckName', item2.option)
      this.$set(item, 'issueScore', item2.score)
    },
    checkBoxChange(item2, item) {
      let data = []
      item.examinationContents.forEach((element) => {
        if (element.chcekId) {
          data.push(element.id)
        }
      })
      this.$set(item, 'checkBoxIds', data)
    },
    getLabel(dict, value) {
      return dict
        .filter((item) => {
          return item.value === value
        })
        .map((item) => {
          return item.label
        })
        .toString()
    },
    cencel() {
      this.form = {}
      this.$router.go(-1)
    },
    doSubmit() {
      this.$refs.form.validate().then(
        (res) => {
          // 验证通过数据提交
          this.$dialog
            .confirm({
              title: "提示",
              message: "你确定要进行提交操作吗？",
            })
            .then(() => {

              let newForm = this.form.examinationBanks.map((item) => {
                item['zbid'] = this.$route.query.zbid
                item['examPaperId'] = this.$route.query.id
                item['taskId'] = this.$route.query.taskId
                item.id = null
                return item
              })
              this.loading = true
              addBatch(newForm).then((res) => {
                report(this.$route.query.zbid).then((stu) => {
                  this.$notify({
                    type: "success",
                    message: "提交成功！",
                    duration: 1500,
                  });
                  this.loading = false
                  this.cencel();
                })
              })
            })
            .catch(() => {
              // on cancel
            });

        },
        (err) => {
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
  },
}
</script>

<style lang="scss" scoped>
#QuestionForm {
  .title-exam {
    width: 100%;
    background-color: #ffffff;
    display: inline-flex;
    justify-content: left;
    align-items: center;
    padding: 15px 0;
  }

  .radio-item {
    margin: 10px 15px;
  }

  .checkbox-item {
    margin: 10px 15px;
  }
}
</style>